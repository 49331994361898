import handleError from '@/misc/handleError'
import decode from 'jwt-decode'
import API from '@sport-travel/api'
import router from '@/router'

const auth = API.Auth
const mainUser = API.User
const tickets = API.Tickets
const ticketTransfer = API.TicketTransfer
const roles = ['admin', 'agent', 'organizer', 'coach', 'player', 'profile']

const state = {
  user: {},
  userCountry: localStorage.getItem('user-country'),
  accessToken: localStorage.getItem('accessToken'),
  refreshToken: localStorage.getItem('refreshToken'),
  loading: false,
  requireVerification: false,
  origin: null,
  actionOrigin: null,
  flagAppBar: true
}

const getters = {
  payload: () => decode(state.accessToken),

  requireVerification: () => state.requireVerification,
  getUser: (state) => {
    if (state.user.data) return state.user.data
    return state.user
  },
  actionOrigin: (state) => {
    return state.actionOrigin
  },
  getFlagAppBar: (state) => {
    return state.flagAppBar
  }
}

const actions = {
  setFlagAppBar (context, value) {
    context.commit('setFlagAppBar', value)
  },
  /*
   *  Handles authenticating the current user
   *  Call this on app init
   */
  async checkAuthentication (context) {
    if (!state.accessToken) {
      return
    }
    if (new Date(context.getters.payload.exp * 1000) < new Date()) {
      await context.dispatch('refreshToken')
    } else {
      if (!state.user?.data?.id) {
        await context.dispatch('getUserInformation')
      }
      if (state.user?.data?.id && state.user?.data?.emailVerified === 1) {
        await context.dispatch('redirectToDashboard')
      }
    }
  },
  async checkExpAccessToken (context) {
    if (!state.accessToken) {
      return
    }
    if (new Date(context.getters.payload.exp * 1000) < new Date()) {
      try {
        const { data } = await auth.refreshToken(context.state.refreshToken)
        context.commit('setAccessToken', data.accessToken)
        context.commit('setUserCountry', data?.user?.person?.address?.country)
      } catch (error) {
        handleError(error, context)
      }
    }
  },
  async getUserInformation (context) {
    sessionStorage.setItem('userID', context.getters.payload.data.userID)
    if (state.accessToken && state.accessToken !== 'undefined') {
      const nonce = Math.floor(Math.random() * 999999999)
      const user = await mainUser.fetchById(
        context.getters.payload.data.userID + `?${nonce}`
      )
      context.commit('setUser', user)
      context.commit('setUserCountry', user.data?.person?.address?.country)
    }
  },
  async refreshToken (context) {
    context.commit('setLoading', true)
    try {
      console.warn('JWT Expired. Attempting to refresh.')
      const { data } = await auth.refreshToken(context.state.refreshToken)
      context.commit('setAccessToken', data.accessToken)
      context.commit('setUserCountry', data?.user?.person?.address?.country)
      context.dispatch('redirectToDashboard')
    } catch (error) {
      handleError(error, context)
    } finally {
      context.commit('setLoading', false)
    }
  },
  async redirectToDashboard (context) {
    const userID = context.getters.payload.data.userID
    const userRoles = context.getters.payload.data.roles
    let redirectPath = router.currentRoute.query.redirect
    const role = redirectPath && roles.find((r) => redirectPath.includes(r))
    let url
    const qs = Object.keys(router.currentRoute.query).map(key => key + '=' + router.currentRoute.query[key]).join('&')
    const indexStorePath = router.resolve({ name: 'CheckoutCart' }).href

    if (redirectPath) {
      if (!redirectPath?.startsWith('/')) {
        redirectPath = '/' + redirectPath
      }
      localStorage.setItem('redirectUrl', redirectPath)
    }

    if ((redirectPath && role && userRoles[role]) || (redirectPath && role === 'profile')) {
      url = `${window.location.protocol}//${window.location.host}${redirectPath}/?${qs}`
    } else {
      // let highestRole = roles.find((r) => userRoles[r])
      // TODO: set up redirect accord new role user
      // if (highestRole === 'player') highestRole = 'profile'
      const highestRole = 'profile'
      url = `${window.location.protocol}//${window.location.host}/dashboard/${highestRole}/?${qs}`
      const dataTickets = await tickets.fetch(
        {
          userID: userID
        }
      )

      if (redirectPath === indexStorePath && state.origin === 'register') {
        url = `${window.location.protocol}//${window.location.host}/dashboard/profile/checkout-cart`
      }

      if (redirectPath === indexStorePath && state.origin === 'login') {
        url = `${window.location.protocol}//${window.location.host}${indexStorePath}`
      }

      if (dataTickets.data && redirectPath !== indexStorePath) {
        if (dataTickets.data.collectionLength > 0) {
          url = `${window.location.protocol}//${window.location.host}/dashboard/profile/`
        }
      }
      // Verified if user buy tickets
      const dataTransfers = await ticketTransfer.fetch(
        {
          receiverID: userID,
          status: 'Pending'
        }
      )
      if (dataTransfers.data && redirectPath !== indexStorePath) {
        if (dataTransfers.data.collectionLength > 0) {
          url = `${window.location.protocol}//${window.location.host}/dashboard/profile/tickets?status=pending`
        }
      }
      if (context.getters.actionOrigin === 'register-form' || context.getters.actionOrigin === 'volunteer-form' || redirectPath?.includes('/live')) {
        if (state.origin === 'register') {
          localStorage.removeItem('currentTeamID')
        }
        url = `${window.location.protocol}//${window.location.host}${redirectPath}`
      }
    }
    if (process.env.NODE_ENV !== 'development') {
      window.location.replace(url)
    } else {
      console.warn(`[DEVELOPMENT]: Redirection to ${url} not possible`)
    }
  },
  async redirectToLogin (context, redirectPath) {
    if (redirectPath) {
      if (Array.isArray(redirectPath)) {
        window.location.replace(`${window.location.protocol}//${window.location.host}/login/?email=${redirectPath[1]}&redirect=${redirectPath[0]}`)
      } else {
        window.location.replace(`${window.location.protocol}//${window.location.host}/login/?redirect=${redirectPath}`)
      }
    } else {
      window.location.replace(`${window.location.protocol}//${window.location.host}/login/`)
    }
  },

  async login (context, body) {
    try {
      const { data } = await auth.login(body)
      context.commit('setAccessToken', data.accessToken)
      context.commit('setRefreshToken', data.refreshToken)
      context.commit('setUserCountry', data?.user?.person?.address?.country)
      context.commit('setUser', data?.user)

      // email verification launch redirectToDashboard
      // await context.dispatch('redirectToDashboard')
    } catch (err) {
      handleError(err, context)
    }
  },
  async register (context, params) {
    try {
      const { data } = await auth.register(params)
      context.commit('setAccessToken', data.accessToken)
      context.commit('setRefreshToken', data.refreshToken)
      context.commit('setUserCountry', data?.user?.person?.address?.country)
      context.commit('setUser', data?.user)

      // email verification launch redirectToDashboard
      // await context.dispatch('redirectToDashboard')
      return data
    } catch (err) {
      handleError(err, context)
    }
  },
  async registerWithoutSets (context, params) {
    try {
      const { data } = await auth.register(params)
      return data
    } catch (err) {
      handleError(err, context)
    }
  },
  async registerNotLogin (context, params) {
    try {
      await auth.register(params)
    } catch (err) {
      handleError(err, context)
    }
  },
  async checkEmail (context, params) {
    try {
      const { data } = await auth.checkEmail(params)
      return data
    } catch (err) {
      handleError(err, context)
    }
  },
  async checkEmailNoError (context, params) {
    try {
      const { data } = await auth.checkEmail(params)
      return data
    } catch (err) {
      if (err) {
        return null
      }
    }
  },
  async recover (context, params) {
    try {
      const { data } = await auth.recover(params)
      return data
    } catch (err) {
      handleError(err, context)
    }
  },
  async recoverCallback (context, params) {
    try {
      const { data } = await auth.recoverCallback(params)
      return data
    } catch (err) {
      handleError(err, context)
    }
  },
  async verify (context, params) {
    try {
      const { data } = await auth.verify(params)
      return data
    } catch (err) {
      handleError(err, context)
    }
  },
  async verifyCallback (context, params) {
    try {
      const { data } = await auth.verifyCallback(params)
      return data
    } catch (err) {
      handleError(err, context)
    }
  },

  requireVerification (context, value) {
    context.commit('setRequireVerification', value)
  },
  redirectToClient (context, newPath) {
    if (process.env.NODE_ENV !== 'development') {
      window.location.replace(`${window.location.protocol}//${window.location.host}/${newPath}`)
    } else {
      console.warn(`[DEVELOPMENT]: Redirection to /${newPath} not possible`)
    }
  },
  logout (context) {
    context.commit('setAccessToken', '')
    context.commit('setRefreshToken', '')
    context.commit('setUser', {})
    localStorage.clear()
    sessionStorage.clear()
    window.location.replace(`${window.location.protocol}//${window.location.host}/login/`)
  }
}

const mutations = {
  setFlagAppBar (state, value) {
    state.flagAppBar = value
  },
  setLoading (state, value) {
    state.loading = value
  },
  setAccessToken (state, value) {
    state.accessToken = value
    localStorage.setItem('accessToken', value)
  },
  setRefreshToken (state, value) {
    state.refreshToken = value
    localStorage.setItem('refreshToken', value)
  },
  setRequireVerification (state, value) {
    state.requireVerification = value
  },
  setUser (state, value) {
    state.user = value
  },
  setUserCountry (state, value) {
    state.userCountry = value
    localStorage.setItem('user-country', value)
  },
  setOrigin (state, value) {
    state.origin = value
  },
  SET_ACTION_ORIGIN (state, value) {
    state.actionOrigin = value
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
