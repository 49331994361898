import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './misc/vuetify'
import VueHead from 'vue-head'
// import MatchSocket from './misc/socket'
import components from '@sport-travel/components'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib'
import * as VueGoogleMaps from 'vue2-google-maps'
import MasonryWall from '@yeger/vue2-masonry-wall'
import VClamp from 'vue-clamp'
import VueCarousel from 'vue-carousel'

import '@/misc/logo.js'
import '@/styles/style.scss'
Vue.config.productionTip = false

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API,
    libraries: 'places,geometry'
  }
})

Vue.use(
  VueHead
  // MatchSocket
)

// Add custom Tournkey components
Object.keys(components).forEach(name => {
  Vue.component(name, components[name])
})

Vue.use(VueTelInputVuetify, {
  vuetify
})

Vue.component('VClamp', VClamp)
Vue.use(MasonryWall)
Vue.use(VueCarousel)

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
