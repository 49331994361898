const state = {
  _currentProfile: {},
  _showProfileForm: false,
  _validProfileForm: false,
  _currentTeam: {},
  _currentDivision: {},
  _currentDivisionRegistrations: [],
  _disciplines: [],
  _disciplinesSelected: [],
  _showTeamForm: false,
  _enableDivisionStep: false,
  _validateOrRegisterTeam: false,
  _continueToNextStep: false,
  _teamCheckCreate: false,
  _teamCreatedNextStep: false,
  _registrationSurvey: false,
  _registrationSurveyCompleted: false,
  _registrationHotel: false,
  _registrationProducts: false,
  _termsAndRefundAgree: false,
  _volunteerTermsAndConditions: false,
  _hasTeamsList: false,
  _skipStep: 0,
  _registrationModel: null,
  _registrationFlow: null
}

const getters = {
  currentProfile: (state) => {
    return state._currentProfile
  },
  showProfileForm: (state) => {
    return state._showProfileForm
  },
  validProfileForm: (state) => {
    return state._validProfileForm
  },
  currentTeam: (state) => {
    return state._currentTeam
  },
  currentDivision: (state) => {
    return state._currentDivision
  },
  currentDivisionRegistrations: (state) => {
    return state._currentDivisionRegistrations
  },
  disciplines: (state) => {
    return state._disciplines
  },
  disciplinesSelected: (state) => {
    return state._disciplinesSelected
  },
  showTeamForm: (state) => {
    return state._showTeamForm
  },
  enableDivisionStep: (state) => {
    return state._enableDivisionStep
  },
  validateOrRegisterTeam: (state) => {
    return state._validateOrRegisterTeam
  },
  continueToNextStep: (state) => {
    return state._continueToNextStep
  },
  teamCheckCreate: (state) => {
    return state._teamCheckCreate
  },
  teamCreatedNextStep: (state) => {
    return state._teamCreatedNextStep
  },
  registrationSurvey: (state) => {
    return state._registrationSurvey
  },
  registrationSurveyCompleted: (state) => {
    return state._registrationSurveyCompleted
  },
  registrationHotel: (state) => {
    return state._registrationHotel
  },
  registrationProducts: (state) => {
    return state._registrationProducts
  },
  termsAndRefundAgree: (state) => {
    return state._termsAndRefundAgree
  },
  volunteerTermsAndConditions: (state) => {
    return state._volunteerTermsAndConditions
  },
  hasTeamsList: (state) => {
    return state._hasTeamsList
  },
  skipStep: (state) => {
    return state._skipStep
  },
  registrationModel: (state) => {
    return state._registrationModel
  },
  registrationFlow: (state) => {
    return state._registrationFlow
  }
}

const mutations = {
  SET_CURRENT_PROFILE (state, value) {
    state._currentProfile = value
  },
  SET_SHOW_PROFILE_FORM (state, value) {
    state._showProfileForm = value
  },
  SET_VALID_PROFILE_FORM (state, value) {
    state._validProfileForm = value
  },
  SET_CURRENT_TEAM (state, value) {
    state._currentTeam = value
  },
  SET_CURRENT_DIVISION (state, value) {
    state._currentDivision = value
  },
  SET_CURRENT_DIVISION_REGISTRATIONS (state, value) {
    state._currentDivisionRegistrations = value
  },
  SET_DISCIPLINES (state, value) {
    state._disciplines = value
  },
  SET_DISCIPLINES_SELECTED (state, value) {
    state._disciplinesSelected = value
  },
  SET_SHOW_TEAM_FORM (state, value) {
    state._showTeamForm = value
  },
  SET_ENABLE_DIVISION_STEP (state, value) {
    state._enableDivisionStep = value
  },
  SET_VALIDATE_OR_REGISTER_TEAM (state, value) {
    state._validateOrRegisterTeam = value
  },
  SET_CONTINUE_TO_NEXT_STEP (state, value) {
    state._continueToNextStep = value
  },
  SET_TEAM_CHECK_CREATE (state, value) {
    state._teamCheckCreate = value
  },
  SET_TEAM_CREATED_NEXT_STEP (state, value) {
    state._teamCreatedNextStep = value
  },
  SET_REGISTRATION_SURVEY (state, value) {
    state._registrationSurvey = value
  },
  SET_REGISTRATION_SURVEY_COMPLETE (state, value) {
    state._registrationSurveyCompleted = value
  },
  SET_REGISTRATION_HOTEL (state, value) {
    state._registrationHotel = value
  },
  SET_REGISTRATION_PRODUCTS (state, value) {
    state._registrationProducts = value
  },
  SET_TERMS_AND_REFUND_AGREE (state, value) {
    state._termsAndRefundAgree = value
  },
  SET_VOLUNTEER_TERMS_AND_CONDITIONS (state, value) {
    state._volunteerTermsAndConditions = value
  },
  SET_HAS_TEAMS_LIST (state, value) {
    state._hasTeamsList = value
  },
  SKIP_STEP (state) {
    state._skipStep++
  },
  SET_REGISTRATION_MODEL (state, value) {
    state._registrationModel = value
  },
  SET_REGISTRATION_FLOW (state, value) {
    state._registrationFlow = value
  },
  RESET_REGISTRATION_PROCESS (state, value) {
    state._currentProfile = {}
    state._showProfileForm = false
    state._validProfileForm = false
    state._currentTeam = {}
    state._currentDivision = {}
    state._currentDivisionRegistrations = []
    state._disciplines = []
    state._disciplinesSelected = []
    state._showTeamForm = false
    state._enableDivisionStep = false
    state._validateOrRegisterTeam = false
    state._continueToNextStep = false
    state._teamCheckCreate = false
    state._teamCreatedNextStep = false
    state._registrationSurvey = false
    state._registrationSurveyCompleted = false
    state._registrationHotel = false
    state._registrationProducts = false
    state._termsAndRefundAgree = false
    state._volunteerTermsAndConditions = false
    state._hasTeamsList = false
    state._skipStep = 0
    state._registrationModel = null
    state._registrationFlow = null
  }
}

export default {
  state,
  getters,
  mutations,
  namespaced: true
}
