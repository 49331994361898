const API_KEY = process.env.VUE_APP_GOOGLE_SITE_KEY
const CALLBACK_NAME = 'recaptchaCallback'
const ONLOAD_NAME = 'recaptchaOnload'

let initialized = !!window.grecaptcha
let resolveInitPromise
let rejectInitPromise
// This promise handles the initialization
// status of the google maps script.
const initPromise = new Promise((resolve, reject) => {
  resolveInitPromise = resolve
  rejectInitPromise = reject
})

export default function init (elementID, callback) {
  // If Google Maps already is initialized
  // the `initPromise` should get resolved
  // eventually.

  if (initialized) {
    initialized = false
    delete window.grecaptcha
    if (document.querySelector('script[name=recaptcha-api]')) {
      document.querySelector('script[name=recaptcha-api]').remove()
    }
  }

  if (initialized) return initPromise
  initialized = true

  // The callback function is called by
  // the Google Maps script if it is
  // successfully loaded.
  window[CALLBACK_NAME] = (response) => {
    callback(response)
    resolveInitPromise(window.grecaptcha)
  }

  window[ONLOAD_NAME] = () => {
    window.grecaptcha.render(elementID, {
      sitekey: API_KEY,
      callback: window[CALLBACK_NAME],
      theme: 'dark'
    })
    resolveInitPromise(window.grecaptcha)
  }

  // We inject a new script tag into
  // the `<head>` of our HTML to load
  // the Google Maps script.
  const script = document.createElement('script')
  script.setAttribute('name', 'recaptcha-api')
  script.async = true
  script.defer = true
  script.src = `https://www.google.com/recaptcha/api.js?onload=${ONLOAD_NAME}&render=explicit`
  script.onerror = rejectInitPromise
  document.querySelector('head').appendChild(script)

  return initPromise
}
